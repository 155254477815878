@import url('./fonts.css');

body {
    background-color: #F9F9F9;
    /* font-family: 'Geist Medium', 'OpenSauceOneMedium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; 
    letter-spacing: -0.02em; */
}

/* p {
    font-family: 'Geist Regular';
}

.font-semibold {
    font-family: 'Geist SemiBold', 'OpenSauceOneSemiBold';
} */

.loader {
    display: inline-block;
    border: 2px solid #F9F9F9;
    border-top: 2px solid #fff;
    border-radius: 50%;
    background: transparent;
    width: 16px;
    height: 16px;
    animation: spin 0.5s linear infinite;
}
  
.loader-dark {
    border: 2px solid #F9F9F9;
    border-top: 2px solid #1f1f1f;
    width: 18px;
    height: 18px;
    margin: 0 auto;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }