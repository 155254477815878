/* src/fonts.css */

@font-face {
    font-family: 'Geist Variable VF';
    src: url('../public/fonts/GeistVariableVF.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* src: url('../public/fonts/GeistVariableVF.ttf') format('truetype'); */
    /* src: url('../public/fonts/GeistVariableVF.woff') format('woff'); */
    /* Add other font properties if needed */
    /* ... */
  }
  
  @font-face {
    font-family: 'Geist Black';
    src: url('../public/fonts/Geist-Black.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* ... */
  }
  
  @font-face {
    font-family: 'Geist Bold';
    src: url('../public/fonts/Geist-Bold.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* ... */
  }
  
  @font-face {
    font-family: 'Geist Light';
    src: url('../public/fonts/Geist-Light.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* ... */
  }
  
  @font-face {
    font-family: 'Geist Medium';
    src: url('../public/fonts/Geist-Medium.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* ... */
  }
  
  @font-face {
    font-family: 'Geist Regular';
    src: url('../public/fonts/Geist-Regular.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* ... */
  }
  
  @font-face {
    font-family: 'Geist SemiBold';
    src: url('../public/fonts/Geist-SemiBold.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* ... */
  }
  
  @font-face {
    font-family: 'Geist Thin';
    src: url('../public/fonts/Geist-Thin.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* ... */
  }
  
  @font-face {
    font-family: 'Geist UltraBlack';
    src: url('../public/fonts/Geist-UltraBlack.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* ... */
  }
  
  @font-face {
    font-family: 'Geist UltraLight';
    src: url('../public/fonts/Geist-UltraLight.woff2') format('woff2');
    /* Add other font formats here if needed */
    /* ... */
  }